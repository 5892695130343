<template>
  <div>
    <app-header
      :table-view-route="{
        name: 'overview',
        params: { projectId: $route.params.projectId.toString(), overviewId: '108' }
      }"
      :dashboard-view-route="{
        name: 'dashboard',
        params: { projectId: $route.params.projectId.toString(), dashboardId: '1' }
      }"
    />
    <ti-dialog ref="selectProjectDialog">
      <project-select-dialog />
    </ti-dialog>

    <ti-dialog ref="copyMoveSettingsDialog">
      <copy-move-settings-dialog />
    </ti-dialog>
    <div v-show="!loading" class="body">
      <div class="zIndex-1">
        <editors v-if="workingObject" screen="frmProjectDetails"></editors>
      </div>
      <boxes
        v-if="workingObject"
        :options="{
          data: subLocations,
          prGuid: workingObject.PrGuid,
          title: 'columnTitles.MpSublocation',
          type: 'subLocationBox',
          businesskey: 'subLocation',
          modal: 'slModal',
          buttons: ['addButton'],
          enableBoxLink: true,
          projectId: $route.params.projectId
        }"
      ></boxes>
      <MeasurementPointBoxes v-if="workingObject" :projectId="`${$route.params.projectId}`" />
      <ProjectParticipantsBoxes v-if="workingObject" :projectId="`${$route.params.projectId}`" />
      <boxes
        v-if="workingObject"
        :options="{
          data: concernedContacts,
          title: 'label.concernedContacts',
          type: 'concernedContactBox',
          businesskey: 'concernedContact',
          modal: 'concernedContactModal',
          isfieldRequest: false,
          buttons: ['addButton'],
          enableBoxLink: true,
          projectId: $route.params.projectId
        }"
      ></boxes>
      <boxes
        v-if="workingObject"
        :options="{
          data: labAssignments,
          title: 'label.labassignments',
          type: 'labAssignmentBox',
          businesskey: 'labassignments',
          isfieldRequest: false,
          buttons: ['addButton', 'empty', 'empty', 'analysisResultStatusButton'],
          enableBoxLink: true,
          projectId: $route.params.projectId
        }"
      ></boxes>
      <SummaryAndConclusionBoxes v-if="workingObject" :projectId="`${$route.params.projectId}`" />
      <boxes
        v-if="workingObject"
        :options="{
          data: pictures,
          prGuid: workingObject.PrGuid,
          title: 'label.pictures',
          type: 'pictureBoxes',
          businesskey: 'pictures',
          buttons: ['addButton', 'empty', 'empty', 'linkButton'],
          modal: 'uploadModal',
          uploadType: mediaClassifications.ProjectPhoto,
          enableBoxLink: true,
          externalLink: externalPicturesLink
        }"
      ></boxes>
      <boxes
        v-if="workingObject"
        :options="{
          data: labCertificates,
          prGuid: workingObject.PrGuid,
          title: 'label.LabCertificates',
          type: 'labCertificateBox',
          businesskey: 'labCertificates',
          buttons: ['addButton', 'empty', 'empty', 'linkButton'],
          modal: 'uploadModal',
          uploadType: mediaClassifications.LabCertificates,
          enableBoxLink: true,
          externalLink: externalLabCertificates
        }" />
      <boxes
        v-if="workingObject"
        :options="{
          data: mapImages,
          prGuid: workingObject.PrGuid,
          title: 'label.mapScreenshot',
          type: 'mapImagesBox',
          businesskey: 'mapImages',
          modal: 'uploadModal',
          uploadType: mediaClassifications.MapImages,
          buttons: ['addButton', 'empty', 'empty', 'linkButton'],
          enableBoxLink: true,
          externalLink: externalMapImagesLink
        }"
      ></boxes>
      <boxes
        v-if="workingObject"
        :options="{
          data: projectDocuments,
          prGuid: workingObject.PrGuid,
          title: 'label.ProjectDocuments',
          type: 'projectDocumentBox',
          modal: 'uploadModal',
          businesskey: 'projectDocuments',
          uploadType: mediaClassifications.ProjectDocuments,
          buttons: ['addButton', 'empty', 'empty', 'linkButton'],
          enableBoxLink: true,
          externalLink: externalProjectDocumentLink
        }"
      ></boxes>
      <boxes
        v-if="workingObject"
        :options="{
          data: mapLayers,
          prGuid: workingObject.PrGuid,
          title: 'label.MapLayers',
          type: 'mapLayerBox',
          modal: 'uploadModal',
          businesskey: 'mapLayers',
          uploadType: mediaClassifications.MapLayers,
          buttons: ['addButton', 'empty', 'empty', 'empty'],
          enableBoxLink: true,
          externalLink: externalMapLayerLink
        }"
      ></boxes>
      <boxes
        v-if="workingObject"
        :options="{
          data: klicFiles,
          prGuid: workingObject.PrGuid,
          title: 'label.KLICFiles',
          type: 'klicFileBox',
          modal: 'uploadModal',
          businesskey: 'KLICFiles',
          uploadType: mediaClassifications.KLICFiles,
          buttons: ['empty', 'empty', 'empty', 'linkButton'],
          enableBoxLink: true,
          externalLink: externalKLICFilesLink
        }"
      ></boxes>
      <div id="projectContextMenuPopupMp" class="right-click-menu" :title="$t('tooltips.duplicateMeasurementPoint')">
        <li v-if="selectedElement.type === 'MEASUREMENTPOINT' && viewMenu" @click="duplicateMeasurementPoint">
          {{ $t('project.DuplicateMeasurementPoint') }}
        </li>
      </div>
      <div id="projectContextMenuPopup" class="right-click-menu" v-bind:class="{ showingPopup: pinnedUserBiggerThanOne }">
        <li v-if="selectedElement.type === 'USER'" @click="unpinUserFromProject">
          {{ $t('project.RemoveUserFromProject') }}
        </li>
        <li v-if="selectedElement.type === 'PICTURE'" @click="openMediaDeleteModal">
          {{ $t('media.deleteModalTitle') }}
        </li>
        <li v-if="selectedElement.type === 'ATTACHMENT'" @click="openMediaDeleteModal">
          {{ $t('attachment.deleteModalTitle') }}
        </li>
        <li
          v-if="selectedElement.type === 'LABASSIGNMENT'"
          :disabled="selectedElement.labAssignment.LbIsSent === 'true'"
          :class="{
            'disabled disabledStyling': selectedElement.labAssignment.LbIsSent === 'true'
          }"
          @click="openMediaDeleteModal"
        >
          {{ $t('contextMenu.DeleteLabAssignment') }}
        </li>
      </div>
    </div>
    <app-footer />
    <delete-modal></delete-modal>
    <nv-loading :show="loading || subLocationLoading || summaryAndConclusionsLoading"></nv-loading>

    <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup"/>
  </div>
</template>

<script>
import $ from 'jquery'
import Vue from 'vue'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import config from '@/configurations/config'
import Popup from '@/utils/popup'
import nvLoading from '@/components/_shared/loading.vue'
import Editors from '@/components/editors.vue'
import boxes from '@/components/boxes.vue'
import workingObjectUtils from '@/utils/workingObjectUtils'
import { PROJECT_TYPE, CONCERNED_COMPANY_TYPES_CODE_LIST, CONTACT_PERSON_FUNCTIONS_CODE_LIST, SUMMARY_AND_CONCLUSION_CODE_LIST, codeListIDMapping } from '@/vuex/constants'
import deleteModal from '@/components/modal/deleteModal.vue'
import TiDialog from '@/components/_shared/tiDialog.vue'
import ProjectSelectDialog from '@/components/modalSelects/projectSelectDialog.vue'
import CopyMoveSettingsDialog from '@/components/modalSelects/copyMoveSettingsDialog.vue'
import { MediaService } from '@/services/MediaWS/mediaService'
import { ProjectService } from '@/services/DataWS/projectService'
import { LabAssignmentService } from '@/services/DataWS/labAssignmentService'
import { ConcernedContactsService } from '@/services/DataWS/concernedContactsService'
import { CodeListService } from '@/services/DataWS/codeListService'
import { ProjectUserService } from '@/services/DataWS/projectUserService'
import SummaryAndConclusionBoxes from '@/components/boxes/summaryAndConclusionBoxes'
import ProjectParticipantsBoxes from '@/components/boxes/projectParticipantsBoxes'
import MeasurementPointBoxes from '@/components/boxes/measurementPointBoxes'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

const mediaService = new MediaService()
const projectService = new ProjectService()
const labAssignmentService = new LabAssignmentService()
const concernedContactsService = new ConcernedContactsService()
const codeListService = new CodeListService()
const projectUserService = new ProjectUserService()

let popup = Popup[config.platform].default
let optionData = {
  SearchFilter: '',
  PageSize: 30,
  LastRowNR: 0,
  OrderPreviewBy: 0
}
// editors is used
// eslint-disable-next-line no-unused-vars
var editors

export default {
  name: 'project',
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    Editors,
    boxes,
    deleteModal,
    TiDialog,
    ProjectSelectDialog,
    CopyMoveSettingsDialog,
    SummaryAndConclusionBoxes,
    ExportProjectPopup,
    MeasurementPointBoxes,
    ProjectParticipantsBoxes
  },
  data() {
    return {
      project: {},
      loading: true,
      subLocationLoading: true,
      summaryAndConclusionsLoading: true,
      usersPinned: [],
      externalMapImagesLink: '',
      externalPicturesLink: '',
      externalProjectDocumentLink: '',
      externalMapLayerLink: '',
      externalKLICFilesLink: '',
      externalLabCertificates: '',
      actionCode: '',
      prMpValidationError: '',
      selectedMp: '',
      selectedId: '',
      viewMenu: false,
      exportPopupOpen: false
    }
  },
  created() {
    this.labCertificates = []
    Promise.all([
      this.$store.dispatch('setSummaryAndConclusionsTypes', codeListIDMapping[SUMMARY_AND_CONCLUSION_CODE_LIST]),
      this.$store.dispatch('fetchTblSubLocations', { PrID: this.$route.params.projectId }).then(() => {
        this.subLocationLoading = false
      }),

      this.$store.dispatch('fetchTblSummaryConclusions', { PrID: this.$route.params.projectId }).then(() => {
        this.summaryAndConclusionsLoading = false
      }),

      this.setSelectedProject()
    ])
  },
  computed: {
    labAssignmentStatusCodes: {
      get() {
        return this.$store.state.labAssignmentStatusCodes
      },
      set(value) {
        this.$store.commit('setLabAssignmentStatusCodes', value)
      }
    },
    sikbLabStatusCodes: {
      get() {
        return this.$store.state.sikbLabStatusCodes
      },
      set(value) {
        this.$store.commit('setSikbLabStatusCodes', value)
      }
    },
    fieldMatrixCodes: {
      get() {
        return this.$store.state.fieldMatrixCodes
      },
      set(value) {
        this.$store.commit('setFieldMatrixCodes', value)
      }
    },
    measurementPoints: {
      get() {
        return this.$store.state.measurementPoints
      },
      set(value) {
        this.$store.commit('setMeasurementPoints', value)
      }
    },
    labAssignments: {
      get() {
        return this.$store.state.labAssignments
      },
      set(value) {
        this.$store.commit('setLabAssignments', value)
      }
    },
    mapImages: {
      get() {
        return this.$store.state.mapImages
      },
      set(value) {
        this.$store.commit('setMapImages', value)
      }
    },
    pictures: {
      get() {
        return this.$store.state.pictures
      },
      set(value) {
        this.$store.commit('setPictures', value)
      }
    },
    labCertificates: {
      get() {
        return this.$store.state.labCertificates
      },
      set(value) {
        this.$store.commit('setLabCertificates', value)
      }
    },
    projects: {
      get() {
        return this.$store.state.projects
      },
      set(value) {
        this.$store.commit('setProjects', value)
      }
    },
    concernedContacts: {
      get() {
        return this.$store.state.concernedContacts
      },
      set(value) {
        this.$store.commit('setConcernedContacts', value)
      }
    },
    [CONTACT_PERSON_FUNCTIONS_CODE_LIST]: {
      get() {
        return this.$store.state.codeLists[CONTACT_PERSON_FUNCTIONS_CODE_LIST]
      },
      set(value) {
        this.$store.commit('setCodeList', {
          list: CONTACT_PERSON_FUNCTIONS_CODE_LIST,
          value: value
        })
      }
    },
    [CONCERNED_COMPANY_TYPES_CODE_LIST]: {
      get() {
        return this.$store.state.codeLists[CONCERNED_COMPANY_TYPES_CODE_LIST]
      },
      set(value) {
        this.$store.commit('setCodeList', {
          list: CONCERNED_COMPANY_TYPES_CODE_LIST,
          value: value
        })
      }
    },
    workingObject: {
      get() {
        return this.$store.state.workingObject
      },
      set(value) {
        this.$store.commit('setWorkingObject', value)
      }
    },
    workingObjectType: {
      get() {
        return this.$store.state.workingObjectType
      },
      set(value) {
        this.$store.commit('setWorkingObjectType', value)
      }
    },
    workingObjectIndex: {
      get() {
        return this.$store.state.workingObjectIndex
      },
      set(value) {
        this.$store.commit('setWorkingObjectIndex', value)
      }
    },
    selectedElement: {
      get() {
        return this.$store.state.selectedElement
      },
      set(value) {
        this.$store.commit('setSelectedElement', value)
      }
    },
    subLocations: {
      get() {
        return this.$store.getters.getSubLocations
      }
    },
    summaryAndConclusions: {
      get() {
        return this.$store.getters.getSummaryAndConclusions
      }
    },
    mapLayers: {
      get() {
        return this.$store.state.mapLayers
      },
      set(value) {
        this.$store.commit('setMapLayers', value)
      }
    },
    projectDocuments: {
      get() {
        return this.$store.state.projectDocuments
      },
      set(value) {
        this.$store.commit('setProjectDocuments', value)
      }
    },
    klicFiles: {
      get() {
        return this.$store.state.klicFiles
      },
      set(value) {
        this.$store.commit('setKlicFiles', value)
      }
    },
    pinnedUserBiggerThanOne() {
      let count = 0
      let l = this.usersPinned.length
      for (let i = 0; i < l; i++) {
        if (this.usersPinned[i].selected) count++
      }
      return count > 0
    },
    mediaClassifications() {
      return config.constants.MediaClassification || {}
    }
  },
  watch: {
    mapLayers: {
      deep: true,
      immediate: false,
      async handler(value) {
        if (value.length > 0 && this.externalMapLayerLink === '') {
          await mediaService.getProjectMediaLocationLink(this.workingObject.PrGuid, config.constants.MediaClassification.MapLayers).then((response) => {
            this.setMediaLink(response, config.constants.MediaClassification.MapLayers)
          })
        }
      }
    },
    projectDocuments: {
      deep: true,
      immediate: false,
      async handler(value) {
        if (value.length > 0 && this.externalProjectDocumentLink === '') {
          await mediaService.getProjectMediaLocationLink(this.workingObject.PrGuid, config.constants.MediaClassification.ProjectDocuments).then((response) => {
            this.setMediaLink(response, config.constants.MediaClassification.ProjectDocuments)
          })
        }
      }
    },
    mapImages: {
      deep: true,
      immediate: false,
      async handler(value) {
        if (value.length > 0 && this.externalMapImagesLink === '') {
          await mediaService.getProjectMediaLocationLink(this.workingObject.PrGuid, config.constants.MediaClassification.MapImages).then((response) => {
            this.setMediaLink(response, config.constants.MediaClassification.MapImages)
          })
        }
      }
    },
    pictures: {
      deep: true,
      immediate: false,
      async handler(value) {
        if (value.length > 0 && this.externalPicturesLink === '') {
          await mediaService.getProjectMediaLocationLink(this.workingObject.PrGuid, config.constants.MediaClassification.ProjectPhoto).then((response) => {
            this.setMediaLink(response, config.constants.MediaClassification.ProjectPhoto)
          })
        }
      }
    },
    klicFiles: {
      deep: true,
      immediate: false,
      async handler(value) {
        if (value.length > 0 && this.externalKLICFilesLink === '') {
          mediaService.getProjectMediaLocationLink(this.workingObject.PrGuid, config.constants.MediaClassification.KLICFiles).then((response) => {
            this.setMediaLink(response, config.constants.MediaClassification.KLICFiles)
          })
        }
      }
    },
    labCertificates: {
      deep: true,
      immediate: false,
      async handler(value) {
        if (value.length > 0 && this.externalLabCertificates === '') {
          mediaService.getProjectMediaLocationLink(this.workingObject.PrGuid, config.constants.MediaClassification.LabCertificates).then((response) => {
            this.setMediaLink(response, config.constants.MediaClassification.LabCertificates)
          })
        }
      }
    }
  },
  methods: {
    openMediaDeleteModal() {
      let modal = $('#modalDelete')
      modal.on('shown.bs.modal', function (e) {
        $('#txtActionCode').focus()
      })
      modal.modal('toggle')
    },
    handlerError(err) {
      if (err && err.ResultCode && err.ResultCode === 'MeasurementPoint_UNKNOWN_ERROR') {
        popup.popup(err.ResultMessage)
        return
      }
      popup.popup(err.body)
    },
    unpinUserFromProject() {
      let l = this.usersPinned.length
      var promises = []
      for (let i = 0; i < l; i++) {
        if (this.usersPinned[i].selected) {
          promises.push(
            projectUserService.pinUnpinUser({
              PrID: this.workingObject.PrID,
              UserName: this.usersPinned[i].UserName,
              Enabled: false
            })
          )
        }
      }
      Promise.All(promises)
      this.usersPinned = this.usersPinned.filter((item) => item.selected === false)
    },
    getMpGuidOnSelectContextMenu(MpGuid, MpID) {
      this.openRightClickMenu()
      this.selectedMp = MpGuid
      this.selectedId = MpID
    },
    async duplicateMeasurementPoint() {
      let options = await this.$refs.copyMoveSettingsDialog.open({
        title: this.$t('label.options'),
        buttonLabel: this.$t('label.duplicateMeasurementPoint')
      })

      if (options) {
        if (options.action === 'move' || options.action === 'copy_move') {
          let projectSelect = await this.$refs.selectProjectDialog.open({
            title: this.$t('label.selectProject'),
            buttonLabel: this.$t('button.confirm')
          })

          if (projectSelect) {
            options.projectId = projectSelect.PrID
          } else {
            return
          }
        } else {
          options.projectId = Number(this.$route.params.projectId)
        }
        this.loading = true
        this.$store
          .dispatch('duplicateMeasurementPoint', {
            options: options,
            selection: [this.selectedMp]
          })
          .then(
            (res) => {
              this.loading = false
              this.closeRightClickMenu()
              window.location.reload(true)
            },
            (err) => {
              console.error(err.message)
              popup.popup(this.$t('message.UnknownError'))
              this.loading = false
            }
          )
      }
    },
    addPinnedUser(user) {
      Vue.set(user, 'selected', false)
      this.usersPinned.push(user)
    },
    setSelectedProject() {
      optionData.ProjectID = this.$route.params.projectId
      this.mapImages = []
      this.pictures = []
      this.mapLayers = []
      this.projectDocuments = []
      this.klicFiles = []
      let tasks = []
      tasks.push(projectService.getSingleProject(optionData.ProjectID))
      tasks.push(projectUserService.getProjectUsersPinned(optionData.ProjectID))
      tasks.push(labAssignmentService.getProjectLabassignments(optionData.ProjectID))
      tasks.push(concernedContactsService.getConcernedContacts(optionData.ProjectID))
      tasks.push(codeListService.getCodeListByCategoryIDOrCode(codeListIDMapping[CONCERNED_COMPANY_TYPES_CODE_LIST]))
      tasks.push(codeListService.getCodeListByCategoryIDOrCode(codeListIDMapping[CONTACT_PERSON_FUNCTIONS_CODE_LIST]))
      tasks.push(codeListService.getCodeListByCategoryIDOrCode(62)) // Lab Status
      tasks.push(codeListService.getCodeListByCategoryIDOrCode(20)) // Sikb Lab Code
      tasks.push(codeListService.getCodeListByCategoryIDOrCode(253)) // Field Matrix
      this.loading = true
      return Promise.all(tasks)
        .then(
          ([
            projectRes,
            projectUsersPinnedRes,
            projectLabassignmentsRes,
            concernedContactsRes,
            concernedCompanyTypes,
            contactPersonFunctions,
            labAssignmentStatusCodes,
            sikbLabStatusCodes,
            fieldMatrixCodes
          ]) => {
            this.loading = false

            // Load the S&C code list!

            // set codelists before setting concernedcontacts as they are currently used by the boxes
            this[CONCERNED_COMPANY_TYPES_CODE_LIST] = concernedCompanyTypes
            this[CONTACT_PERSON_FUNCTIONS_CODE_LIST] = contactPersonFunctions

            this.concernedContacts = concernedContactsRes.tblConcernedCompanies
            this.labAssignmentStatusCodes = labAssignmentStatusCodes
            this.sikbLabStatusCodes = sikbLabStatusCodes
            this.fieldMatrixCodes = fieldMatrixCodes

            if (projectRes.data && projectRes.data.ResultCode === 'Export_Succes') {
              let projectResponse = JSON.parse(projectRes.data.Files[0].FileContent)

              this.projects = [projectResponse.VeldData.tblProjects]
              this.workingObject = this.projects[0]
              this.workingObjectType = PROJECT_TYPE
              this.workingObjectIndex = 0
            } else {
              console.error('ERROR: getSingleProject response was unsuccessful')
            }

            if (projectUsersPinnedRes.data && projectUsersPinnedRes.data.ResultCode === 'Export_Succes') {
              if (typeof projectUsersPinnedRes.data.Files !== 'undefined' && typeof projectUsersPinnedRes.data.Files[0] !== 'undefined') {
                let up = JSON.parse(projectUsersPinnedRes.data.Files[0].FileContent)
                up.forEach(function (u) {
                  Vue.set(u, 'selected', false)
                })
                this.usersPinned = up
              }
            } else {
              console.error('ERROR: getProjectUsersPinned response was unsuccessful')
            }
            // Reset the lab assignments before retrieving them
            this.labAssignments = []
            if (projectLabassignmentsRes.data && projectLabassignmentsRes.data.ResultCode === 'Export_Succes') {
              if (typeof projectLabassignmentsRes.data.Files !== 'undefined' && typeof projectLabassignmentsRes.data.Files[0] !== 'undefined') {
                let up = JSON.parse(projectLabassignmentsRes.data.Files[0].FileContent)
                if (typeof up.VeldData !== 'undefined' && typeof up.VeldData.tblLabassignments !== 'undefined') {
                  if (Array.isArray(up.VeldData.tblLabassignments) === true) {
                    up = up.VeldData.tblLabassignments
                  } else {
                    // if tblLabAssignments is an object (happens if there is only one labassignment, then make it an array
                    up = [up.VeldData.tblLabassignments]
                  }

                  up.forEach(function (u) {
                    u.selected = false
                  })

                  up.reverse()

                  this.labAssignments = up
                }
              }
            } else {
              console.error('ERROR: getProjectLabassignments response was unsuccessful')
            }
          }
        )
        .then((res) => {
          return this.$store.dispatch('setActiveTitle', this.workingObject.PrCode)
        })
        .then((res) => {
          return this.$store.dispatch('setCurrentProject', this.workingObject)
        })
        .then((res) => {
          return this.$store.dispatch('setPrTypeCode', this.workingObject.PrTypeCode)
        })
        .then(async (values) => {
          // Second round of data-queries, for queries that need results from the first set of queries (such as the PrGuid)
          let tasks = []
          tasks.push(
            await this.$store.dispatch('getProjectMediaList', {
              mediaType: this.mediaClassifications.ProjectPhoto
            }).then(response => {
              this.handleMediaResponse(response, config.constants.MediaClassification.ProjectPhoto)
            })
          )
          tasks.push(
            await this.$store.dispatch('getProjectMediaList', {
              mediaType: this.mediaClassifications.MapImages
            }).then(response => {
              this.handleMediaResponse(response, config.constants.MediaClassification.MapImages)
            })
          )
          tasks.push(
            await this.$store.dispatch('getProjectMediaList', {
              mediaType: this.mediaClassifications.MapLayers
            }).then(response => {
              this.handleMediaResponse(response, config.constants.MediaClassification.MapLayers)
            })
          )
          tasks.push(
            await this.$store.dispatch('getProjectMediaList', {
              mediaType: this.mediaClassifications.ProjectDocuments
            }).then(response => {
              this.handleMediaResponse(response, config.constants.MediaClassification.ProjectDocuments)
            })
          )
          tasks.push(
            await this.$store.dispatch('getProjectMediaList', {
              mediaType: this.mediaClassifications.KLICFiles
            }).then(response => {
              this.handleMediaResponse(response, config.constants.MediaClassification.KLICFiles)
            })
          )
          tasks.push(
            await this.$store.dispatch('getProjectMediaList', {
              mediaType: this.mediaClassifications.LabCertificates
            }).then(response => {
              this.handleMediaResponse(response, config.constants.MediaClassification.LabCertificates)
            })
          )

          return window.Promise.all(tasks).catch((err) => {
            this.loading = false
            this.handlerError(err)
          })
        })
        .catch((err) => {
          this.loading = false
          this.handlerError(err)
        })
    },

    setMediaLink(mediaRes, mediaClassification) {
      if (mediaRes.data && mediaRes.data.ResultCode === 'GetMediaLink_Success') {
        if (typeof mediaRes.data.Link !== 'undefined') {
          switch (mediaClassification) {
            case config.constants.MediaClassification.MapImages:
              this.externalMapImagesLink = mediaRes.data.Link
              break
            case config.constants.MediaClassification.ProjectDocuments:
              this.externalProjectDocumentLink = mediaRes.data.Link
              break
            case config.constants.MediaClassification.MapLayers:
              this.externalMapLayerLink = mediaRes.data.Link
              break
            case config.constants.MediaClassification.ProjectPhoto:
              this.externalPicturesLink = mediaRes.data.Link
              break
            case config.constants.MediaClassification.KLICFiles:
              this.externalKLICFilesLink = mediaRes.data.Link
              break
            case config.constants.MediaClassification.LabCertificates:
              this.externalLabCertificates = mediaRes.data.Link
              break
            default:
              this.handlerError(new Error('ERROR: Unknown mediaClassification: ' + mediaClassification))
          }
        }
      }
    },
    handleMediaResponse(mediaListRes, mediaClassification) {
      if (!mediaListRes.data || mediaListRes.data.ResultCode !== 'GetMediaList_Success') return
      if (typeof mediaListRes.data.files === 'undefined') return
      let files = mediaListRes.data.files
      switch (mediaClassification) {
        case config.constants.MediaClassification.MapImages:
          this.mapImages = files
          break
        case config.constants.MediaClassification.ProjectPhoto:
          this.pictures = files
          break
        case config.constants.MediaClassification.MapLayers:
          this.mapLayers = files
          break
        case config.constants.MediaClassification.ProjectDocuments:
          this.projectDocuments = files
          break
        case config.constants.MediaClassification.KLICFiles:
          this.klicFiles = files
          break
        case config.constants.MediaClassification.LabCertificates:
          this.labCertificates = files
          break
        default:
          this.handlerError(new Error('ERROR: Unknown mediaClassification: ' + mediaClassification))
      }
    },
    save() {
      this.loading = true
      if (this.workingObject.PrDrillingMasterCode !== undefined && !this.workingObject.PrFieldworkFirm) {
        this.workingObject.PrFieldworkFirm = '-'
      }

      if (this.workingObject.PrLeaderCode !== undefined && !this.workingObject.PrConsultancyFirm) {
        this.workingObject.PrConsultancyFirm = '-'
      }
      return this.$store.dispatch('setCurrentProject', this.workingObject).then(() => {
        return workingObjectUtils
          .pushWorkingObjectToDataService()
          .then((res) => {
            this.loading = false
            return true
          })
          .catch((err) => {
            this.loading = false
            this.handlerError(err)
          })
      })
    },
    async generateReport() {
      if (this.$store.state.workingObjectChanged && this.workingObject.PrID) {
        await this.save()
      }
      if (this.workingObject.PrID) {
        window.open(config.apps.report + this.workingObject.PrID, '_blank').focus()
      }
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    },
    async xyzImport() {
      if (this.$store.state.workingObjectChanged && this.workingObject.PrID) {
        await this.save()
      }
      if (this.workingObject.PrID) {
        this.$router.push({
          name: 'ExternalXYZ',
          params: {
            PrID: this.workingObject.PrID
          }
        })
      }
    },
    gotoOvamOverview() {
      this.$router.push({
        path: '/project/' + this.$route.params.projectId + '/overview/1'
      })
    },
    deleteProject() {
      this.selectedElement = {
        type: 'PROJECT',
        id: 'project-' + this.$route.params.projectId
      }
      this.openMediaDeleteModal()
    },
    closeRightClickMenu() {
      this.viewMenu = false
    },
    openRightClickMenu() {
      this.viewMenu = true
    }
  }
}
</script>

<!-- The with of a new list of boxes is 200px -->
<style scoped lang="less">
.body {
  width: 2630px;
}
.disabledStyling {
  color: black;
  cursor: not-allowed;
}
</style>
