<template>
  <div class="popup-contents">
    <div>
      <label class="unselectable checkBoxWrapper">{{$t('label.action')}}</label>
      <select v-model="mutationOptions.action" class="form-control" @change="onChange($event)">
        <option value="copy">{{$t('dropdown.copy')}}</option>
        <option value="move">{{$t('dropdown.move')}}</option>
        <option value="copy_move">{{$t('dropdown.copy_move')}}</option>
      </select>
    </div>
    <div>
      <div>
        <label class="checkBoxWrapper">
          <input
            id="copyFieldSamples"
            v-model="mutationOptions.copyFieldSamples"
            type="checkbox"
            :disabled="!mutationOptions.allowCopySamples"
          />
          {{$t('label.copyFieldSamples')}}
        </label>
      </div>
      <div>
        <label class="checkBoxWrapper">
          <input
            id="copyCoordinates"
            v-model="mutationOptions.copyCoordinates"
            type="checkbox"
            :disabled="!mutationOptions.allowCopyCoordinates"
          />
          {{$t('label.copyCoordinates')}}
        </label>
      </div>
    </div>
    <div>
      <label class="unselectable checkBoxWrapper">{{$t('label.copyAmount')}}</label>
      <input
        id="copies"
        v-model="mutationOptions.copies"
        class="form-control"
        type="number"
        placeholder="1"
        :disabled="!mutationOptions.allowNumCopies"
        min="1"
        max="100"
        @input="onCopiesChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'copyMoveSettingsDialog',
  inject: ['validate', 'options'],
  data () {
    return {
      mutationOptions: {
        projectId: this.currentProjectId,
        sourceProjectId: this.currentProjectId,
        action: 'copy', // Enum copy or move
        copyFieldSamples: true, // Whether to process the field samples
        copyCoordinates: true, // Whether to process the coordinates of a measurement point
        copies: 1, // Number of copies to perform,
        allowCopySamples: true,
        allowCopyCoordinates: true,
        allowNumCopies: true
      }
    }
  },
  mounted () {
    this.onChange()
  },
  computed: {
    currentProjectId() {
      return this.$route.params.projectId
    }
  },
  methods: {
    onCopiesChange (e) {
      // make sure the number of copies does not exceed 100
      try {
        this.mutationOptions.copies =
          parseInt(this.mutationOptions.copies) > 100
            ? 100
            : this.mutationOptions.copies
      } catch (err) {
        this.mutationOptions.copies = 1
      }
    },
    onChange () {
      // Set the button by default

      this.options.buttonLabel = this.$t('label.duplicateMeasurementPoint')
      this.options.numSelections = this.options.numSelections || 1

      switch (this.mutationOptions.action) {
        case 'copy':
          // Enable all options and set the copies to 1
          this.mutationOptions.projectId = this.currentProjectId
          this.mutationOptions.sourceProjectId = this.currentProjectId
          this.mutationOptions.copyFieldSamples = false
          this.mutationOptions.copyCoordinates = false
          this.mutationOptions.copies = 1
          this.mutationOptions.allowCopySamples = true
          this.mutationOptions.allowCopyCoordinates = true
          this.mutationOptions.allowNumCopies = this.options.numSelections === 1
          break
        case 'move':
          this.mutationOptions.projectId = null
          this.mutationOptions.sourceProjectId = this.currentProjectId
          this.mutationOptions.copyFieldSamples = true
          this.mutationOptions.copyCoordinates = true
          this.mutationOptions.copies = 1
          this.mutationOptions.allowCopySamples = false
          this.mutationOptions.allowCopyCoordinates = false
          this.mutationOptions.allowNumCopies = false
          this.options.buttonLabel = this.$t('button.next')
          break
        case 'copy_move':
          this.mutationOptions.projectId = null
          this.mutationOptions.sourceProjectId = this.currentProjectId
          this.mutationOptions.copyFieldSamples = false
          this.mutationOptions.copyCoordinates = false
          this.mutationOptions.copies = 1
          this.mutationOptions.allowCopySamples = true
          this.mutationOptions.allowCopyCoordinates = true
          this.mutationOptions.allowNumCopies = false
          this.options.buttonLabel = this.$t('button.next')
          break
      }
      this.validate(this.mutationOptions)
    }
  }
}
</script>

<style scoped>
/deep/ .dx-toolbar {
  background-color: white !important;
}
p.label  {
  display: block;
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Segoe UI','Open Sans', sans-serif;
  color: #333;
  text-align: left;
}
table.options-data {
  width: 100%;
}
table.options-data td,
table.options-data th {
  border: 1px solid #ccc;
  padding: 5px;
}
.popup-contents > div {
  margin-bottom: 10px;
}
.popup-contents .table-scroll {
    max-height: 200px;
    overflow-y: scroll;
}
</style>
