var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-header',{attrs:{"table-view-route":{
      name: 'overview',
      params: { projectId: _vm.$route.params.projectId.toString(), overviewId: '108' }
    },"dashboard-view-route":{
      name: 'dashboard',
      params: { projectId: _vm.$route.params.projectId.toString(), dashboardId: '1' }
    }}}),_c('ti-dialog',{ref:"selectProjectDialog"},[_c('project-select-dialog')],1),_c('ti-dialog',{ref:"copyMoveSettingsDialog"},[_c('copy-move-settings-dialog')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"body"},[_c('div',{staticClass:"zIndex-1"},[(_vm.workingObject)?_c('editors',{attrs:{"screen":"frmProjectDetails"}}):_vm._e()],1),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        data: _vm.subLocations,
        prGuid: _vm.workingObject.PrGuid,
        title: 'columnTitles.MpSublocation',
        type: 'subLocationBox',
        businesskey: 'subLocation',
        modal: 'slModal',
        buttons: ['addButton'],
        enableBoxLink: true,
        projectId: _vm.$route.params.projectId
      }}}):_vm._e(),(_vm.workingObject)?_c('MeasurementPointBoxes',{attrs:{"projectId":`${_vm.$route.params.projectId}`}}):_vm._e(),(_vm.workingObject)?_c('ProjectParticipantsBoxes',{attrs:{"projectId":`${_vm.$route.params.projectId}`}}):_vm._e(),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        data: _vm.concernedContacts,
        title: 'label.concernedContacts',
        type: 'concernedContactBox',
        businesskey: 'concernedContact',
        modal: 'concernedContactModal',
        isfieldRequest: false,
        buttons: ['addButton'],
        enableBoxLink: true,
        projectId: _vm.$route.params.projectId
      }}}):_vm._e(),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        data: _vm.labAssignments,
        title: 'label.labassignments',
        type: 'labAssignmentBox',
        businesskey: 'labassignments',
        isfieldRequest: false,
        buttons: ['addButton', 'empty', 'empty', 'analysisResultStatusButton'],
        enableBoxLink: true,
        projectId: _vm.$route.params.projectId
      }}}):_vm._e(),(_vm.workingObject)?_c('SummaryAndConclusionBoxes',{attrs:{"projectId":`${_vm.$route.params.projectId}`}}):_vm._e(),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        data: _vm.pictures,
        prGuid: _vm.workingObject.PrGuid,
        title: 'label.pictures',
        type: 'pictureBoxes',
        businesskey: 'pictures',
        buttons: ['addButton', 'empty', 'empty', 'linkButton'],
        modal: 'uploadModal',
        uploadType: _vm.mediaClassifications.ProjectPhoto,
        enableBoxLink: true,
        externalLink: _vm.externalPicturesLink
      }}}):_vm._e(),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        data: _vm.labCertificates,
        prGuid: _vm.workingObject.PrGuid,
        title: 'label.LabCertificates',
        type: 'labCertificateBox',
        businesskey: 'labCertificates',
        buttons: ['addButton', 'empty', 'empty', 'linkButton'],
        modal: 'uploadModal',
        uploadType: _vm.mediaClassifications.LabCertificates,
        enableBoxLink: true,
        externalLink: _vm.externalLabCertificates
      }}}):_vm._e(),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        data: _vm.mapImages,
        prGuid: _vm.workingObject.PrGuid,
        title: 'label.mapScreenshot',
        type: 'mapImagesBox',
        businesskey: 'mapImages',
        modal: 'uploadModal',
        uploadType: _vm.mediaClassifications.MapImages,
        buttons: ['addButton', 'empty', 'empty', 'linkButton'],
        enableBoxLink: true,
        externalLink: _vm.externalMapImagesLink
      }}}):_vm._e(),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        data: _vm.projectDocuments,
        prGuid: _vm.workingObject.PrGuid,
        title: 'label.ProjectDocuments',
        type: 'projectDocumentBox',
        modal: 'uploadModal',
        businesskey: 'projectDocuments',
        uploadType: _vm.mediaClassifications.ProjectDocuments,
        buttons: ['addButton', 'empty', 'empty', 'linkButton'],
        enableBoxLink: true,
        externalLink: _vm.externalProjectDocumentLink
      }}}):_vm._e(),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        data: _vm.mapLayers,
        prGuid: _vm.workingObject.PrGuid,
        title: 'label.MapLayers',
        type: 'mapLayerBox',
        modal: 'uploadModal',
        businesskey: 'mapLayers',
        uploadType: _vm.mediaClassifications.MapLayers,
        buttons: ['addButton', 'empty', 'empty', 'empty'],
        enableBoxLink: true,
        externalLink: _vm.externalMapLayerLink
      }}}):_vm._e(),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        data: _vm.klicFiles,
        prGuid: _vm.workingObject.PrGuid,
        title: 'label.KLICFiles',
        type: 'klicFileBox',
        modal: 'uploadModal',
        businesskey: 'KLICFiles',
        uploadType: _vm.mediaClassifications.KLICFiles,
        buttons: ['empty', 'empty', 'empty', 'linkButton'],
        enableBoxLink: true,
        externalLink: _vm.externalKLICFilesLink
      }}}):_vm._e(),_c('div',{staticClass:"right-click-menu",attrs:{"id":"projectContextMenuPopupMp","title":_vm.$t('tooltips.duplicateMeasurementPoint')}},[(_vm.selectedElement.type === 'MEASUREMENTPOINT' && _vm.viewMenu)?_c('li',{on:{"click":_vm.duplicateMeasurementPoint}},[_vm._v(" "+_vm._s(_vm.$t('project.DuplicateMeasurementPoint'))+" ")]):_vm._e()]),_c('div',{staticClass:"right-click-menu",class:{ showingPopup: _vm.pinnedUserBiggerThanOne },attrs:{"id":"projectContextMenuPopup"}},[(_vm.selectedElement.type === 'USER')?_c('li',{on:{"click":_vm.unpinUserFromProject}},[_vm._v(" "+_vm._s(_vm.$t('project.RemoveUserFromProject'))+" ")]):_vm._e(),(_vm.selectedElement.type === 'PICTURE')?_c('li',{on:{"click":_vm.openMediaDeleteModal}},[_vm._v(" "+_vm._s(_vm.$t('media.deleteModalTitle'))+" ")]):_vm._e(),(_vm.selectedElement.type === 'ATTACHMENT')?_c('li',{on:{"click":_vm.openMediaDeleteModal}},[_vm._v(" "+_vm._s(_vm.$t('attachment.deleteModalTitle'))+" ")]):_vm._e(),(_vm.selectedElement.type === 'LABASSIGNMENT')?_c('li',{class:{
          'disabled disabledStyling': _vm.selectedElement.labAssignment.LbIsSent === 'true'
        },attrs:{"disabled":_vm.selectedElement.labAssignment.LbIsSent === 'true'},on:{"click":_vm.openMediaDeleteModal}},[_vm._v(" "+_vm._s(_vm.$t('contextMenu.DeleteLabAssignment'))+" ")]):_vm._e()])],1),_c('app-footer'),_c('delete-modal'),_c('nv-loading',{attrs:{"show":_vm.loading || _vm.subLocationLoading || _vm.summaryAndConclusionsLoading}}),_c('ExportProjectPopup',{attrs:{"exportPopupOpen":_vm.exportPopupOpen},on:{"closeButtonClicked":_vm.toggleExportPopup}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }