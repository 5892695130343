<template>
  <div>
    <div id="modalDelete" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalDeleteLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
            <h4 id="modalDeleteProjectLabel" class="modal-title">
              {{ title }}
            </h4>
          </div>
          <div class="modal-body">
            <p>{{ text }}</p>
            <input v-if="!noNeedForConfirmation" id="txtActionCode" v-model="actionCode" type="text" class="form-control" @keypress="checkSubmit" />
          </div>
          <div class="modal-footer">
            <button id="btnDeleteButton" type="button" class="btn" :disabled="!enableDeleteButton" @click="submitDelete">
              {{ $t('project.DeleteButton') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <nv-loading :show="loading"></nv-loading>
  </div>
</template>

<script>
import $ from 'jquery'
import nvLoading from '@/components/_shared/loading.vue'
import Popup from '@/utils/popup'
import config from '@/configurations/config.js'
import { MediaService } from '@/services/MediaWS/mediaService'
import { ProjectService } from '@/services/DataWS/projectService'
import { LabAssignmentService } from '@/services/DataWS/labAssignmentService'

const labAssignmentService = new LabAssignmentService()
const projectService = new ProjectService()
const mediaService = new MediaService()

let popup = Popup[config.platform].default

var self

export default {
  name: 'deleteModal',
  components: {
    nvLoading
  },
  data() {
    return {
      actionCode: '',
      loading: false,
      confirmExclusionList: ['LABASSIGNMENT']
    }
  },
  computed: {
    selectedElement: {
      get() {
        return this.$store.state.selectedElement
      }
    },
    workingObject: {
      get() {
        return this.$store.state.workingObject
      }
    },
    title: {
      get() {
        let type = this.$store.state.selectedElement.type

        switch (type) {
          case 'PROJECT':
            return this.$t('project.DeleteProject')
          case 'PICTURE':
            return this.$t('media.deleteModalTitle')
          case 'ATTACHMENT':
            return this.$t('attachment.deleteModalTitle')
          // is undefined when there is no selected item yet and the component is initialized
          case undefined:
            return '' // sometimes happens, should not give error
          case 'USER':
            return ''
          case 'MEASUREMENTPOINT':
            return ''
          case 'LABASSIGNMENT':
            // When no confirmation is needed set this methods set the action code to Delete
            // THis is done instead of using the exlusion list to enable the button due to the button not updating when switching between types
            this.setActionCode()
            return this.$t('deleteModal.DeleteLabAssignmentTitle')
          default:
            console.error('Delete modal contains unexpected selectedelement type: ' + type)
            return ''
        }
      }
    },
    text: {
      get() {
        let type = this.$store.state.selectedElement.type
        switch (type) {
          case 'PROJECT':
            return this.$t('project.DeleteProjectHelp')
          case 'PICTURE':
            return this.$t('media.deleteModalText')
          case 'ATTACHMENT':
            return this.$t('attachment.deleteModalText')
          // is undefined when there is no selected item yet and the component is initialized
          case undefined:
            // currently handled without modal in project.vue
            return ''
          case 'USER':
            return '' // sometimes happens, should give empty string
          case 'MEASUREMENTPOINT':
            return ''
          case 'LABASSIGNMENT':
            return this.$t('deleteModal.DeleteLabAssignmentText')
          default:
            console.error('Delete modal contains unexpected selectedelement type: ' + type)
            return ''
        }
      }
    },
    mapImages: {
      get() {
        return this.$store.state.mapImages
      },
      set(value) {
        this.$store.commit('setMapImages', value)
      }
    },
    pictures: {
      get() {
        return this.$store.state.pictures
      },
      set(value) {
        this.$store.commit('setPictures', value)
      }
    },
    labCertificates: {
      get() {
        return this.$store.state.labCertificates
      },
      set(value) {
        this.$store.commit('setLabCertificates', value)
      }
    },
    mapLayers: {
      get() {
        return this.$store.state.mapLayers
      },
      set(value) {
        this.$store.commit('setMapLayers', value)
      }
    },
    projectDocuments: {
      get() {
        return this.$store.state.projectDocuments
      },
      set(value) {
        this.$store.commit('setProjectDocuments', value)
      }
    },
    klicFiles: {
      get() {
        return this.$store.state.klicFiles
      },
      set(value) {
        this.$store.commit('setKlicFiles', value)
      }
    },
    enableDeleteButton: {
      get() {
        return this.actionCode.toLocaleUpperCase() === 'DELETE'
      }
    },
    noNeedForConfirmation: {
      get() {
        return this.confirmExclusionList.includes(this.selectedElement.type)
      }
    }
  },

  methods: {
    checkSubmit(evt) {
      if (evt.key === 'Enter' && !$('#btnDeleteButton').is(':disabled')) {
        this.submitDelete()
      }
    },
    setActionCode() {
      this.actionCode = 'DELETE'
    },
    submitDelete() {
      switch (this.selectedElement.type) {
        case 'PROJECT':
          this.deleteProject()
          break
        case 'PICTURE':
        case 'ATTACHMENT':
          this.deleteMedia()
          break
        case 'LABASSIGNMENT':
          this.deleteLabAssignment()
          break
        default:
          break
      }
      this.actionCode = ''
      $('#btnDeleteButton').attr('disabled', true)
    },
    async deleteLabAssignment() {
      this.loading = true
      await labAssignmentService.deleteLabAssignment(this.selectedElement.labAssignment, this.workingObject).then(
        (result) => {
          this.$store.dispatch('removeItemsFromStoreArray', {
            storeObject: 'labAssignments',
            storeProperty: 'LbID',
            propertyValue: this.selectedElement.labAssignment.LbID
          })
        },
        (error) => {
          this.handlerError(error)
        }
      )
      $('#modalDelete').modal('toggle')
      this.loading = false
    },
    deleteProject() {
      self = this
      this.loading = true
      $('#modalDelete').modal('hide')

      projectService.deleteProject(this.workingObject.PrID, this.workingObject.PrCode, this.workingObject.PrGuid).then(
        (res) => {
          self.loading = false
          self.$router.push('/')
        },
        (err) => {
          console.log(err.message)
          $('#modalDelete').modal('toggle')
          self.loading = false
        }
      )
    },
    deleteMedia() {
      self = this
      var projectGuid = ''
      if (this.workingObject && this.workingObject.PrGuid) {
        projectGuid = this.workingObject.PrGuid
      } else {
        projectGuid = this.$store.state.currentProject.PrGuid
      }
      mediaService.deleteMedia(projectGuid, this.selectedElement.fileName, this.selectedElement.mediaClassification).then(
        (response) => {
          if (response.data.ResultCode === 'DeleteMedia_Success') {
            let arr
            switch (self.selectedElement.mediaClassification) {
              case config.constants.MediaClassification.MapImages:
                arr = this.mapImages
                break
              case config.constants.MediaClassification.MapLayers:
                arr = this.mapLayers
                break
              case config.constants.MediaClassification.ProjectPhoto:
                arr = this.pictures
                break
              case config.constants.MediaClassification.ProjectDocuments:
                arr = this.projectDocuments
                break
              case config.constants.MediaClassification.KLICFiles:
                arr = this.klicFiles
                break
              case config.constants.MediaClassification.LabCertificates:
                arr = this.labCertificates
                break
              default:
                arr = []
                break
            }

            let index = arr.findIndex((x) => x.FileName === self.selectedElement.fileName)

            if (index !== -1) {
              arr.splice(index, 1)
            }

            this.$emit('item-deleted', self.selectedElement.fileName)

            $('#modalDelete').modal('hide')
          } else {
            this.handlerError(new Error('ERROR: Deleting media' + response.data.ResultCode))
          }
        },
        (err) => {
          self.loading = false
          self.handlerError(err)
        }
      )
    },
    handlerError(err) {
      if (err && err.ResultCode && err.ResultCode === 'MeasurementPoint_UNKNOWN_ERROR') {
        popup.popup(err.ResultMessage)
        return
      }

      popup.popup(err.body)
    }
  }
}
</script>

<style>
</style>
