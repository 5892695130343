<template>
  <div :id="boxElementId">
    <router-link :to="routeLink" class="routerLinkBaseBox">
      <div :class="isSelected ? 'selected' : ''">
        <div class="baseBox" :class="{ 'baseBoxBorderNone': isBorderVisible }">
          <div class="baseBoxText">
            <slot />
          </div>
          <div v-show="isSelected" class="baseBoxFlagTriangle" />
          <div v-show="isSelected" class="baseBoxFlag">
            <font-awesome-icon icon="fa-solid fa-check" class="baseBoxFlagIcon" />
          </div>
        </div>
      </div>
    </router-link>
    <DxContextMenu cssClass="baseBoxContextMenu" :data-source="contextMenuItems" width="40px" :target="targetBoxElementId" @item-click="itemClick" />
  </div>
</template>

<script>
import DxContextMenu from 'devextreme-vue/context-menu'

import '@/assets/less/colors.less'
export default {
  name: 'BaseBox',
  components: {
    DxContextMenu
  },
  props: {
    boxItemInfo: {
      type: Object,
      required: true
    },
    routeLink: {
      type: String,
      required: true
    },
    boxType: {
      type: String,
      required: false,
      default: ''
    },
    contextMenuItems: {
      type: Array,
      required: false,
      default: () => []
    },
    sourceElementId: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false
    },
    isBorderVisible: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    boxElementId() {
      return this.sourceElementId
    },
    targetBoxElementId() {
      return '#' + this.sourceElementId
    }
  },
  methods: {
    itemClick(e) {
      switch (e.itemData.text) {
        case `${this.$t('project.DeleteButton')}`:
          this.$emit('delete', this.sourceElementId)
          break
        case `${this.$t('project.DuplicateButton')}`:
          this.$emit('duplicate', this.sourceElementId)
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.routerLinkBaseBox {
  text-decoration: none !important;
}

.baseBox {
  display: flex;
  position: relative;
  background-color: #f3f4f6;
  border: 1px solid @gray300;
  height: 71px;
  margin-top: 5px;
  transition: 0.3s;
  max-width: 100%;

  &.baseBoxBorderNone {
    border: none;
  }

  .baseBoxText {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    color: @gray900;

    /deep/ div>p {
      margin: 0;
      overflow: hidden;
      color: #000000;
      word-wrap: break-word;
      max-width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.title {
        font-weight: bold;
      }
    }
  }

  .baseBoxFlagTriangle {
    border-style: solid;
    border-width: 10px;
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 50%;
    width: 0;
    height: 0;
  }

  .baseBoxFlag {
    position: absolute;
    right: 13px;
    top: 14px;
    width: 2%;
    height: 2%;
    display: flex;
    justify-content: center;
    align-items: center;

    .baseBoxFlagIcon {
      color: #ffffff;
    }
  }

  &:hover,
  &:focus {
    transform: scale(0.95);
  }
}
</style>
