<template>
  <div class="sortingMenu">
    <div class="sortingMenuBackground" @click.self="clickModalSelf" />
    <div class="sortingMenuBody">
      <div class="sortingMenuHeader">
        <button class="sortSquareBox" @click="$emit('sortMenuOpen')">
          <font-awesome-icon icon="fa-solid fa-sort" class="sortIcon" />
        </button>
        <h4>Sort</h4>
      </div>
      <ul class="sortListItems">
        <li :class="{ activeSort: isSortingActive('MpName') }" @click="toggleSorting('MpName')">
          {{ $t('SortableColumns.Name') }} <font-awesome-icon v-if="getSortingIconClass('MpName')" :icon="getSortingIconClass('MpName')" />
        </li>
        <li :class="{ activeSort: isSortingActive('MpNameNumeric') }" @click="toggleSorting('MpNameNumeric')">
          {{ $t('SortableColumns.NumericName') }} <font-awesome-icon v-if="getSortingIconClass('MpNameNumeric')" :icon="getSortingIconClass('MpNameNumeric')" />
        </li>
        <li :class="{ activeSort: isSortingActive('MpDate') }" @click="toggleSorting('MpDate')">
          {{ $t('SortableColumns.Date') }} <font-awesome-icon v-if="getSortingIconClass('MpDate')" :icon="getSortingIconClass('MpDate')" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeasurementPointBoxSorting',
  props: {
    sortingOptions: {
      type: Object,
      required: true
    },
    onSort: {
      type: Function,
      required: true
    },
    selectedMeasurementPointGuid: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    isSortingActive() {
      return (field) => this.sortingOptions.field === field
    }
  },
  methods: {
    toggleSorting(field) {
      if (this.sortingOptions.field === field) {
        this.sortingOptions.order = this.sortingOptions.order === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortingOptions.field = field
        this.sortingOptions.order = 'asc'
      }
      this.onSort(this.sortingOptions)
    },
    getSortingIconClass(field) {
      if (this.sortingOptions.field === field) {
        return this.sortingOptions.order === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'
      }
      return ''
    },
    clickModalSelf() {
      this.$emit('sortMenuOpen')
    }
  }
}
</script>

<style lang="less" scoped>
.sortingMenu {
  position: relative;
  padding: 15px;
  z-index: 101;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  .sortingMenuBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .sortingMenuBody {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 167px;
    color: white;
    background-color: #72b83d;
    z-index: 102;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
.sortingMenuHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  h4 {
    padding-left: 5px;
    margin: 0;
  }
}
.sortListItems {
  list-style: none;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-top: 0.1px solid #fff;
  border-bottom: 0.1px solid #fff;
  li {
    height: 36px;
    padding: 8px;
    font-size: 13px;
    &:hover {
      background-color: rgba(168, 212, 163, 0.5);
    }
    &.activeSort {
      background-color: #72b83d !important;
      color: #fff !important;
    }
  }
}
.sortSquareBox {
  display: flex;
  flex-direction: row;
  border: none;
  background-color: #72b83d;
}
.sortBox:hover {
  transform: scale(0.92);
}
.sortIcon {
  height: 19px;
  color: white;
}
</style>
