<template>
  <div class="boxesBody">
    <div class="titleBox">
      <h5>{{ $t('grid.frmMeasurementPointsGrid') }}</h5>
      <div class="boxesSmallButtons">
        <BaseSquareBox v-if="!showSearch" boxes-class="measurementPoint" icon="fa-solid fa-plus" :on-click="toggleAddMeasurementPointPopup" />
        <BaseSquareBox v-if="!showSearch" boxes-class="measurementPoint" icon="fa-solid fa-sort" :on-click="toggleSortMeasurementPointPopup" />
        <div v-show="sortMenuOpen" class="sortMeasurementMenuPointPopup">
          <sorting-box :sortingOptions="sortingOptions" :on-sort="handleSorting" :selectedMeasurementPointGuid="selectedMeasurementPointGuid" @sortMenuOpen="toggleSortMeasurementPointPopup" />
        </div>
        <BaseSquareBox v-if="!showSearch" boxes-class="measurementPoint" icon="fa-solid fa-map-marked-alt" :on-click="toggleMapMeasurementPointPopup" />
        <div v-if="showSearch" class="input-group">
          <input id="measurementPointNameSearchBox" v-model="measurementPointNameFilter" class="form-control" type="text" />
          <div class="input-group-addon baseSquareBox" v-on:click="enableOrDisableShowSearch">
            <font-awesome-icon icon="fa-magnifying-glass" class="baseSquareBoxIcon" />
          </div>
        </div>
        <BaseSquareBox v-if="!showSearch" boxes-class="measurementPoint" icon="fa-solid fa-magnifying-glass" :on-click="enableOrDisableShowSearch" />
      </div>
    </div>
    <div v-if="!loadingData" class="measurementPointBox">
      <DxScrollView :key="filteredMeasurementpoints.length" ref="dxScrollView" :useNative="true">
        <transition-group name="fade" tag="div">
          <BaseBox v-for="measurementPoint in filteredMeasurementpoints" :key="measurementPoint.MpGuid" :ref="measurementPoint.MpGuid" :boxItemInfo="measurementPoint" :routeLink="routeToMeasurementPoint(measurementPoint)" :sourceElementId="`MeasurementPoint-${measurementPoint.MpGuid}`"
            :contextMenuItems="contextMenuItems" :isSelected="measurementPoint.MpGuid === selectedMeasurementPointGuid" :isBorderVisible="isOnDetails" @delete="toggleDeleteMeasurementPointPopup(measurementPoint)" @duplicate="toggleDuplicateMeasurementPointPopup(measurementPoint)"
            @click.self="itemClick">
            <div class="measurementPointContents">
              <p class="title">{{ measurementPoint.MpName }}</p>
              <p>{{ getNameForMeasurementPointTypeCode(measurementPoint.MpTypeCode) }}</p>
              <p>
                {{ measurementPoint.MpDepth || '-' }} <span v-if="measurementPoint.MpDepth">{{ $t('label.boxesDepth') }}</span>
              </p>
            </div>
          </BaseBox>
        </transition-group>
      </DxScrollView>
    </div>
    <transition name="fade" appear>
      <BasePopup v-show="deletePopupOpen" :popupOpen="deletePopupOpen" :popup-title="$t('project.DeleteMeasurementPointTitle')" right-footer-button-text="project.DeleteButton" :createError="createError" @rightButtonClicked="deleteMeasurementPoint"
        @closeButtonClicked="toggleDeleteMeasurementPointPopup">
        <p>{{ $t('project.DeleteMeasurementPointHelp') }}</p>
      </BasePopup>
    </transition>
    <transition name="fade" appear>
      <BasePopup v-show="createPopupOpen" :popupOpen="createPopupOpen" :popup-title="$t('project.AddMeasurementPointTitle')" right-footer-button-text="project.CreateButton" :createError="createError" :customErrorMessage="customErrorMessage" @rightButtonClicked="createMeasurementPoint"
        @closeButtonClicked="toggleAddMeasurementPointPopup">
        <div class="popupContentBody">
          <div class="popupInput">
            <h4>{{ $t('project.AddMeasurementPointHelp') }}</h4>
            <input v-model="inputTypeValue" type="text" display-expr="name" value-expr="value" :placeholder="$t('project.PlaceholderMeasurementPointsHelp')" class="popupTextBox" v-on:keyup.enter="onEnterCreate" />
          </div>
        </div>
      </BasePopup>
    </transition>
    <ti-dialog ref="selectProjectDialog">
      <project-select-dialog />
    </ti-dialog>
    <ti-dialog ref="copyMoveSettingsDialog">
      <copy-move-settings-dialog />
    </ti-dialog>
    <nv-loading :show="loadingData"></nv-loading>
  </div>
</template>

<script>
import { DxScrollView } from 'devextreme-vue/scroll-view'
import stringUtils from '@/utils/stringUtils.js'
import BaseBox from '@/components/boxes/base-components/baseBox'
import BaseSquareBox from '@/components/boxes/base-components/baseSquareBox'
import BasePopup from '@/components/boxes/base-components/basePopup.vue'
import TiDialog from '@/components/_shared/tiDialog.vue'
import nvLoading from '@/components/_shared/loading.vue'
import ProjectSelectDialog from '@/components/modalSelects/projectSelectDialog.vue'
import CopyMoveSettingsDialog from '@/components/modalSelects/copyMoveSettingsDialog.vue'
import SortingBox from '@/components/boxes/boxes-content/measurementPointBoxSorting.vue'
import cookie from '@/utils/cacheProviders/cookieCacheProvider'
import sortUtils from '@/utils/sortUtils'
import arrayUtils from '@/utils/arrayUtils'

import store from '@/vuex/store'

import '@/assets/css/boxes.less'

export default {
  name: 'MeasurementPointBoxes',
  components: {
    BaseBox,
    BaseSquareBox,
    DxScrollView,
    BasePopup,
    TiDialog,
    nvLoading,
    ProjectSelectDialog,
    CopyMoveSettingsDialog,
    SortingBox
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    selectedMeasurementPointGuid: {
      type: String,
      required: false,
      default: null
    }
  },
  async created() {
    this.loadingData = true
    try {
      await Promise.all([this.setMeasurementPointTypeCodelist(), this.setMeasurementPoints()])
    } finally {
      this.loadingData = false
    }
  },
  mounted() {
    if (!this.selectedMeasurementPointGuid) return
    setTimeout(() => {
      this.scrollToElement(this.selectedMeasurementPointGuid)
    }, 300)
  },
  data() {
    return {
      loadingData: false,
      createPopupOpen: false,
      sortMenuOpen: false,
      sortingOptions: {
        field: '',
        order: 'asc'
      },
      showSortMenu: false,
      mapOpen: false,
      inputTypeValue: '',
      deletePopupOpen: false,
      measurementPointToDelete: {},
      createError: false,
      customErrorMessage: '',
      measurementPoints: [],
      measurementPointTypeCodelist: [],
      showSearch: false,
      measurementPointNameFilter: '',
      contextMenuItems: [
        {
          text: this.$t('project.DuplicateMeasurementPoint'),
          icon: 'copy'
        },
        {
          text: this.$t('project.DeleteButton'),
          icon: 'trash'
        }
      ]
    }
  },
  computed: {
    projectMeasurementPoints() {
      return this.$store.state.measurementPoints
    },
    isOnDetails() {
      return !!this.selectedMeasurementPointGuid
    },
    filteredMeasurementpoints() {
      return this.measurementPoints.filter((measurementPoint) => { return measurementPoint.MpName.toUpperCase().includes(this.measurementPointNameFilter.toUpperCase()) })
    }
  },
  watch: {
    projectId: {
      handler: async function () {
        await this.setMeasurementPoints()
      },
      immediate: true
    },
    inputTypeValue: {
      handler: function (value) {
        this.validateInput(value)
      }
    },
    selectedMeasurementPointGuid: {
      handler: function (newGuid) {
        this.scrollToElement(newGuid)
      }
    },
    projectMeasurementPoints: {
      handler: async function () {
        await this.setMeasurementPoints()
      }
    },
    '$route.params.openMap': {
      handler: function () {
        if (this.$route.params.openMap === 'map') {
          this.mapOpen = true
        } else {
          this.mapOpen = false
        }
      },
      immediate: true
    }
  },
  methods: {
    enableOrDisableShowSearch() {
      this.showSearch = !this.showSearch

      if (this.showSearch) {
        this.$nextTick(() => {
          document.getElementById('measurementPointNameSearchBox').focus()
        })
      } else {
        this.measurementPointNameFilter = ''
      }
    },

    async setMeasurementPoints() {
      const measurementPoints = await this.$store.dispatch('getMeasurementPoints', { projectId: this.projectId })
      this.measurementPoints = measurementPoints
      var sortOptions = cookie.get('sortOptions')
      if (sortOptions) {
        this.handleSorting(JSON.parse(sortOptions))
      }
    },

    toggleSortMeasurementPointPopup() {
      this.sortMenuOpen = !this.sortMenuOpen
    },

    onEnterCreate() {
      if (this.createError) return
      this.createMeasurementPoint()
    },

    handleSorting(options) {
      cookie.set('sortOptions', JSON.stringify(options))

      this.sortingOptions = options

      const field = options.field
      const order = options.order

      this.measurementPoints.sort((a, b) => {
        let aValue = a[field]
        let bValue = b[field]

        if (order === 'desc') {
          [aValue, bValue] = [bValue, aValue]
        }

        if (field === 'MpNameNumeric') {
          const numericA = parseFloat(aValue)
          const numericB = parseFloat(bValue)

          if (!isNaN(numericA) && !isNaN(numericB)) {
            return numericA - numericB
          }

          if (isNaN(numericA)) return 1
          if (isNaN(numericB)) return -1
        }

        return sortUtils.naturalSort(aValue, bValue)
      })
    },

    async setMeasurementPointTypeCodelist() {
      const measurementPointTypeCodelist = await this.$store.dispatch('getCodeListByCategoryIDOrCode', { categoryId: 13 })
      this.measurementPointTypeCodelist = measurementPointTypeCodelist
    },

    getNameForMeasurementPointTypeCode(MpTypeCode) {
      if (!MpTypeCode) return '-'
      return stringUtils.capitalizeFirstLetter(this.measurementPointTypeCodelist.find((code) => code.GcCode === MpTypeCode).GcDescription) || '-'
    },

    toggleAddMeasurementPointPopup() {
      this.createError = false
      this.createPopupOpen = !this.createPopupOpen
      if (this.createPopupOpen) {
        this.inputTypeValue = arrayUtils.getSuggestedName(this.measurementPoints, 'Mp', '')
      }
      if (!this.createPopupOpen) this.inputTypeValue = ''
    },

    toggleMapMeasurementPointPopup() {
      let targetRoute = ''
      this.mapOpen = !this.mapOpen

      if (this.selectedMeasurementPointGuid) {
        targetRoute = `/project/${this.projectId}/MeasurementPoint/${this.selectedMeasurementPointGuid}${this.mapOpen ? '/map' : ''}`
      } else if (this.measurementPoints && this.measurementPoints.length > 0) {
        targetRoute = `/project/${this.projectId}/MeasurementPoint/${this.measurementPoints[0].MpGuid}${this.mapOpen ? '/map' : ''}`
      } else {
        targetRoute = `/project/${this.projectId}/measurementPoint/0${this.mapOpen ? '/map' : ''}`
      }

      this.$router.push(targetRoute)
    },

    async toggleDuplicateMeasurementPointPopup(measurementPoint) {
      let routeToMeasurementPoint = ''
      let options = await this.$refs.copyMoveSettingsDialog.open({
        title: this.$t('label.options'),
        buttonLabel: this.$t('label.duplicateMeasurementPoint')
      })

      if (!options) return
      if (options.action === 'move' || options.action === 'copy_move') {
        const projectSelect = await this.$refs.selectProjectDialog.open({
          title: this.$t('label.selectProject'),
          buttonLabel: this.$t('button.confirm')
        })

        if (!projectSelect) return
        options.projectId = projectSelect.PrID
        if (options.action === 'copy_move') routeToMeasurementPoint = measurementPoint.MpGuid || this.selectedMeasurementPointGuid
      } else {
        options.projectId = Number(this.projectId)
      }

      this.loadingData = true
      let MpGUID = measurementPoint.MpGuid || this.selectedMeasurementPointGuid
      try {
        await this.$store.dispatch('duplicateMeasurementPoint', {
          data: this.measurementPoints,
          options: options,
          selection: [MpGUID]
        })

        this.loadingData = false
        await this.setMeasurementPoints()
        if (this.$route.name === 'project' && options.action !== 'copy') return
        if (this.measurementPoints.length === 0) {
          this.$store.commit('clearWorkingObject')
          this.$router.push({
            name: 'project',
            params: {
              projectId: this.projectId
            }
          })
        } else {
          if (options.action === 'move') return this.goToFirstMeasurementPointIfExists()
          if (options.action === 'copy') routeToMeasurementPoint = this.measurementPoints[this.measurementPoints.length - 1].MpGuid
          this.$nextTick(() => {
            this.$router.push({
              name: 'measurementPoint',
              params: {
                projectId: this.projectId,
                measurementPointId: routeToMeasurementPoint
              }
            })
          })
        }
      } catch (err) {
        console.error(err.message)
        this.loadingData = false
      }
    },

    toggleDeleteMeasurementPointPopup(measurementPoint) {
      this.createError = false
      if (measurementPoint) {
        this.measurementPointToDelete = measurementPoint
      }
      this.deletePopupOpen = !this.deletePopupOpen
    },

    scrollToElement(MpGuid) {
      const selectedElement = this.$refs[MpGuid]
      if (selectedElement) {
        selectedElement[0].$el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      }
    },

    routeToMeasurementPoint(measurementPoint) {
      if (!this.mapOpen) {
        return `/project/${measurementPoint.PrID}/MeasurementPoint/${measurementPoint.MpGuid}`
      } else {
        return `/project/${measurementPoint.PrID}/MeasurementPoint/${measurementPoint.MpGuid}/map`
      }
    },

    navigateToNewMeasurementPoint(newMeasurementPointGuid, projectId) {
      this.createError = false
      this.createPopupOpen = false
      this.inputTypeValue = ''
      this.$router.push({
        name: 'measurementPoint',
        params: {
          projectId: projectId,
          measurementPointId: newMeasurementPointGuid
        },
        query: { new: 'true' }
      })
    },

    async goToFirstMeasurementPointIfExists() {
      const measurementPoints = this.measurementPoints
      if (!measurementPoints || measurementPoints.length === 0) {
        this.$store.commit('clearWorkingObject')
        this.$router.push({ name: 'project', params: { id: this.projectId } })
        return
      }
      const targetRoute = { name: 'measurementPoint', params: { projectId: this.projectId, measurementPointId: measurementPoints[0].MpGuid } }
      this.$router.push(targetRoute).catch((error) => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.error(error)
        }
      })
    },

    validateInput(newValue) {
      if (newValue === '') {
        this.createError = true
        this.customErrorMessage = this.$t('message.mpNameRequired')
      } else if (this.measurementPoints.some((point) => point.MpName === newValue)) {
        this.createError = true
        this.customErrorMessage = this.$t('message.mpNameExists')
      } else {
        this.createError = false
        this.customErrorMessage = ''
      }
    },

    async createMeasurementPoint() {
      if (this.createError) return

      let newMeasurementPointData = {
        MpID: '-1',
        MpName: this.inputTypeValue,
        PrID: store.state.workingObject.PrID,
        MpDate: new Date()
      }

      try {
        this.$store
          .dispatch('createMeasurementPoint', {
            measurementPoint: newMeasurementPointData
          })
          .then((measurementPoints) => {
            this.measurementPoints = measurementPoints
            const selectedMeasurementPoint = measurementPoints.find((measurementPoint) => measurementPoint.MpName === newMeasurementPointData.MpName)
            this.navigateToNewMeasurementPoint(selectedMeasurementPoint.MpGuid, selectedMeasurementPoint.PrID)
          })
      } catch (error) {
        this.createError = true
        console.error(error)
      }
    },

    deleteMeasurementPoint() {
      const mpGuidToDelete = this.measurementPointToDelete.MpGuid || this.selectedMeasurementPointGuid

      this.$store
        .dispatch('deleteMeasurementPoint', {
          projectId: this.projectId,
          measurementPointGuid: mpGuidToDelete
        })
        .then(() => {
          this.measurementPoints = this.measurementPoints.filter((mp) => mp.MpGuid !== mpGuidToDelete)
          this.createError = false
          this.deletePopupOpen = false

          if (this.selectedMeasurementPointGuid) {
            this.goToFirstMeasurementPointIfExists()
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.baseSquareBox {
  color: white;
  width: 40px;
  height: 40pxh;
  border-radius: 0px
}

#measurementPointNameSearchBox {
  height: 40px;
  border-radius: 0px
}

.baseSquareBoxIcon {
  height: 20px
}

.boxesBody {
  width: 200px;
  float: left;
  scroll-behavior: smooth;
  padding: 0px 10px 0;
  text-decoration: none;
}

.measurementPointBox {
  height: calc(100vh - 215px);
  border: solid 1px transparent;
  margin: 5px 0px 5px 0px;
  text-decoration: none;

  .measurementPointContents {
    display: flex;
    flex-direction: column;
  }

  /deep/ .dx-scrollable-scroll-content {
    background-color: rgba(44, 44, 44, 0.7);
    border-radius: 4px;
    width: 5px;
  }

  /deep/ .dx-scrollable-scroll {
    width: 8px !important;
  }
}

/deep/ .baseBoxMark {
  background-color: #72b83d;
}

/deep/ .baseSquareBox {
  background-color: #72b83d;
}

/deep/ .baseBoxFlagTriangle {
  border-color: #72b83d;
}

/deep/ .selected .baseBox {
  border: 1px solid #72b83d;
}

/deep/ .baseBox {
  background: linear-gradient(to right, #72b83d 0 10px, #f3f4f6 0);
}

.popupTextBox {
  width: 600px;
  height: 32px;
  padding-left: 10px;
  border: 1px solid @gray400;
  border-radius: 5px;
}

.errorText {
  color: @error600;
  margin: 5px;
}

.popupInput {
  padding: 15px;

  h4 {
    color: @gray900;
  }
}

.popupContent {
  p {
    padding: 15px;
  }
}

.sortMeasurementMenuPointPopup {
  position: absolute;
  margin-top: -15px;
  margin-left: 35px;
}

/deep/ .popupHeader {
  padding: 15px;
}
</style>
