<template>
  <!-- WARNING: DEPRECATED! DO NOT USE THIS POPUP! Use BasePopup instead! -->
  <div v-show="show" ref="tidialog">
    <div v-if="show" id="tidialogbg" ref="tidialogbg" class="modal modal-backdrop" tabindex="-1" style="display: block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" @click="cancel()">&times;</button>
            <h4 class="modal-title">{{ options.title }}</h4>
            <small v-if="options.subHeader" class="h6">{{ options.subHeader }}</small>
          </div>
          <div class="modal-body">
            <slot />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn" :disabled="!valid" v-bind:class="{ disabled: !valid }" style="min-width: 100px" @click="agree()">{{
              options.buttonLabel }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*

  WARNING: DEPRECATED! DO NOT USE THIS POPUP! Use BasePopup instead!

*/
export default {
  name: 'ti-dialog',
  data: () => ({
    show: false,
    valid: false,
    dataContainer: null,
    options: {
      title: 'Default title',
      subHeader: null,
      buttonLabel: 'OK'
    },
    resolve: null,
    reject: null
  }),
  provide() {
    return {
      options: this.options,
      agree: this.agree,
      cancel: this.cancel,
      validate: this.validate,
      invalidate: this.invalidate
    }
  },
  mounted() {
    this.$refs.tidialog.addEventListener('keyup', this.onKeyUp)
  },
  methods: {
    open(options = null) {
      if (options) {
        this.options = Object.assign(this.options, options)
      }
      this.show = true
      this.valid = false
      this.dataContainer = null
      this.$nextTick(() => {
        this.$refs.tidialogbg.addEventListener('click', this.focusloss)
      })
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.show = false
      this.resolve(this.dataContainer)
    },
    cancel() {
      this.show = false
      this.resolve(null)
    },
    validate(obj) {
      this.dataContainer = obj
      this.valid = true
    },
    invalidate() {
      this.dataContainer = null
      this.valid = false
    },
    onKeyUp(event) {
      switch (event.keyCode) {
        case 27:
          this.cancel()
          break
        case 13:
          if (this.valid) {
            this.agree()
          }
          break
        default:
          break
      }
    },
    focusloss(event) {
      if (event.target.id === 'tidialogbg') {
        this.cancel()
      }
    }
  },
  beforeDestroy() {
    if (this.$refs.tidialog) {
      this.$refs.tidialog.removeEventListener('keyup', this.onKeyUp, { passive: true })
    }
    if (this.$refs.tidialogbg) {
      this.$refs.tidialogbg.removeEventListener('click', this.focusloss, { passive: true })
    }
  }
}
</script>

<style scoped>
.modal-backdrop {
  background: rgba(0, 0, 0, 0.45);
}
</style>
