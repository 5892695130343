<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="popup-content-body">
    <div class="popupInput">
      <h4>{{ $t('project.FinishTypeCode') }}</h4>
      <DxSelectBox v-model:value="inputValue" :tab-index="0" :search-enabled="true" :data-source="typeOptions" search-mode="contains" class="popupSelect" />
      <p v-show="error" class="errorText">{{ $t('message.valueRequired') }}</p>
    </div>
  </div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box'

export default {
  name: 'SummaryAndConclusionsAddPopupContent',
  components: {
    DxSelectBox
  },
  props: {
    value: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    typeOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      typeInput: ''
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value?.toString() || ''
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="less" scoped>

.popup-content-body {
  padding: 15px;
}
.popupSelect {
  width: 600px;
}
.errorText {
  color: @error600;
  margin: 5px;
}
.popupInput {
  h4 {
    color: @gray900;
  }
}
</style>
