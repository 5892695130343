<template>
  <div>
    <div style="position: relative; float: right">
      <search ref="search-component" style="width: 275px" @search="search"></search>
    </div>
    <br />
    <br />
    <dx-tree-list :data-source="projects" key-expr="PrCode" height="300px" :focused-row-enabled="true" :focused-row-key.sync="focusedRow" @row-dbl-click="onRowDblClick">
      <dx-scrolling mode="standard" />
      <dx-column data-field="PrCode" :caption="$t('columnTitles.ProjectCode')"></dx-column>
      <dx-column data-field="PrName" :caption="$t('columnTitles.MpName')"></dx-column>
    </dx-tree-list>
  </div>
</template>

<script>
import { DxTreeList, DxColumn, DxScrolling } from 'devextreme-vue/tree-list'
import _ from 'lodash'

import search from '@/components/_shared/search.vue'
import { ProjectsOverviewService } from '@/services/DataWS/projectsOverviewService'
const projectsOverviewService = new ProjectsOverviewService()

const optionData = {
  SearchFilter: '',
  PageSize: 30,
  LastRowNR: 0,
  OrderPreviewBy: 1
}

export default {
  name: 'projectSelectDialog',
  components: { DxTreeList, DxScrolling, DxColumn, search },
  inject: ['validate'],
  data() {
    return {
      projects: null,
      focusedRow: null
    }
  },
  async mounted() {
    projectsOverviewService.getProjectListByFilter(optionData).then(
      (res) => {
        if (!res.body) {
          return
        }
        let datas = JSON.parse(res.body)
        if (!_.isArray(datas)) {
          datas = [datas]
        }
        this.projects = datas
      },
      (err) => {
        console.log(err)
      }
    )
  },
  watch: {
    focusedRow(name) {
      let record = this.getProjectId(name)
      this.validate(record)
    }
  },
  methods: {
    onRowDblClick(obj) {
      this.focusedRow = obj.key
    },
    getProjectId(prCode) {
      return this.projects.filter((item) => item.PrCode === prCode)[0]
    },
    search(query) {
      optionData.SearchFilter = query
      optionData.LastRowNR = 0

      projectsOverviewService.getProjectListByFilter(optionData).then(
        (res) => {
          if (!res.body) {
            return
          }
          let datas = JSON.parse(res.body)
          if (!_.isArray(datas)) {
            datas = [datas]
          }
          this.projects = datas
        },
        (err) => {
          console.log(err)
        }
      )
    }
  }
}
</script>

<style scoped>
/deep/ .dx-toolbar {
  background-color: white !important;
}
</style>
