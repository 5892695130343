<template>
  <div class="boxesBody">
    <div class="titleBox">
      <h5>{{ $t('tableTitles.ProjectParticipants') }}</h5>
      <div class="boxesSmallButtons">
        <BaseSquareBox boxes-class="projectParticipants" icon="fa-plus" :on-click="openAddProjectParticipantPopup" />
      </div>
    </div>
    <div v-if="!loadingData" class="projectParticipantsBox">
      <DxScrollView :key="pinnedProjectParticipants.length" ref="dxScrollView" :useNative="true">
        <transition-group name="fade" tag="div">
          <BaseBox v-for="projectParticipant in pinnedProjectParticipants" :key="projectParticipant.UserID" :ref="projectParticipant.UserID"
            :boxItemInfo="projectParticipant" routeLink="" :sourceElementId="`projectParticipant-${projectParticipant.UserID}`"
            :contextMenuItems="contextMenuItems" :isSelected="projectParticipant.UserID === projectParticipantSelected.UserID"
            @delete="deleteProjectParticipant(projectParticipant)" @click.self="itemClick">
            <div class="projectParticipantsContents">
              <p class="title">{{ projectParticipant.PersonName }}</p>
              <p>{{ projectParticipant.UserName }}</p>
            </div>
          </BaseBox>
        </transition-group>
      </DxScrollView>
    </div>
    <transition name="fade" appear>
      <BasePopup v-show="createPopupOpen" :popupOpen="createPopupOpen" :popup-title="$t('project.AddSelectedUserTitle')" right-footer-button-text="label.select"
        :createError="createError" @rightButtonClicked="addProjectParticipants" @closeButtonClicked="openAddProjectParticipantPopup">
        <div class="popupContentBody" style="max-width: 700px">
          <div class="popupInput" style="display: flex; flex-direction: column">
            <h4>{{ $t('project.AddSelectedUserHelp') }}</h4>
            <DxDataGrid class="popupSelectBox" :data-source="notPinnedProjectParticipants" :show-borders="true" :show-column-lines="true" :show-row-lines="true"
              :row-alternation-enabled="true" key-expr="UserID" @selectionChanged="onPopupSelectionChange">
              <!-- <DxFilterRow :visible="true" /> -->
              <DxColumn :width="dxColumnWidth" data-field="PersonName" caption="Name" />
              <DxColumn :width="dxColumnWidth" data-field="UserName" caption="Username" />

              <DxScrolling mode="standard" />
              <DxPaging :enabled="false" />
              <DxSelection show-check-boxes-mode="always" mode="multiple" />
              <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
            </DxDataGrid>
          </div>
        </div>
      </BasePopup>
    </transition>
  </div>
</template>

<script>
import { DxScrollView } from 'devextreme-vue/scroll-view'
import { DxDataGrid, DxColumn, DxScrolling, DxPaging, DxSelection, DxSearchPanel } from 'devextreme-vue/data-grid'
import BaseSquareBox from '@/components/boxes/base-components/baseSquareBox.vue'
import BaseBox from '@/components/boxes/base-components/baseBox'
import BasePopup from '@/components/boxes/base-components/basePopup.vue'

import '@/assets/css/boxes.less'

export default {
  name: 'ProjectParticipantsBoxes',
  components: {
    DxScrollView,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxPaging,
    DxSelection,
    BaseSquareBox,
    BaseBox,
    BasePopup,
    DxSearchPanel
  },
  props: {
    projectId: {
      type: String,
      required: true
    }
  },
  async created() {
    this.loadingData = true
    await Promise.all([this.setNotPinnedProjectParticipants(), this.setPinnedProjectParticipants()]).then(() => {
      this.loadingData = false
    })
  },
  data() {
    return {
      loadingData: false,
      createError: false,
      createPopupOpen: false,
      selectedParticipants: [],
      notPinnedProjectParticipants: [],
      pinnedProjectParticipants: [],
      projectParticipantSelected: {},
      dxColumnWidth: 300,
      contextMenuItems: [
        {
          text: this.$t('project.DeleteButton'),
          icon: 'trash'
        }
      ]
    }
  },
  watch: {
    projectId: {
      handler: async function () {
        await this.setPinnedProjectParticipants()
        await this.setNotPinnedProjectParticipants()
      },
      immediate: true
    }
  },
  methods: {
    async setPinnedProjectParticipants() {
      await this.$store.dispatch('getProjectUsersPinned', { projectId: this.projectId }).then((projectUsersPinnedRes) => {
        if (!projectUsersPinnedRes.data || projectUsersPinnedRes.data.ResultCode !== 'Export_Succes') {
          console.error('ERROR: getProjectUsersPinned response was unsuccessful')
          return
        }

        if (projectUsersPinnedRes.data.Files && projectUsersPinnedRes.data.Files[0]) {
          let projectParticipants = JSON.parse(projectUsersPinnedRes.data.Files[0].FileContent)
          this.pinnedProjectParticipants = projectParticipants
        }
      })
    },

    async setNotPinnedProjectParticipants() {
      await this.$store.dispatch('getProjectUsersNotPinned', { projectId: this.projectId }).then((projectUsersNotPinnedRes) => {
        if (!projectUsersNotPinnedRes || !Array.isArray(projectUsersNotPinnedRes)) {
          console.error('ERROR: getNotProjectUsersPinned response was unsuccessful or not an array')
          return
        }
        this.notPinnedProjectParticipants = projectUsersNotPinnedRes
      })
    },

    openAddProjectParticipantPopup() {
      this.createPopupOpen = !this.createPopupOpen
    },

    onPopupSelectionChange(selectedItems) {
      this.selectedParticipants = selectedItems.selectedRowsData
    },

    async addProjectParticipants() {
      const participantsPromises = this.selectedParticipants.map(async (projectParticipant) => {
        await this.$store.dispatch('pinUnpinUser', {
          PrID: this.projectId,
          UserName: projectParticipant.UserName,
          Enabled: true
        })
      })

      await Promise.all(participantsPromises)

      await this.setPinnedProjectParticipants()
      await this.setNotPinnedProjectParticipants()
      this.selectedParticipants = []
      this.createPopupOpen = false
    },

    async deleteProjectParticipant(projectParticipantSelected) {
      this.pinnedProjectParticipants = this.pinnedProjectParticipants.filter((projectParticipant) => projectParticipant.UserID !== projectParticipantSelected.UserID)
      await this.$store.dispatch('pinUnpinUser', {
        PrID: this.projectId,
        UserName: projectParticipantSelected.UserName,
        Enabled: false
      })
      await this.setPinnedProjectParticipants()
      await this.setNotPinnedProjectParticipants()
    }
  }
}
</script>

<style lang="less" scoped>
.popupContentBody {
  height: calc(80vh - 128px);
}

.boxesBody {
  width: 200px;
  float: left;
  scroll-behavior: smooth;
  padding: 0px 10px 0;
  text-decoration: none;
}

.projectParticipantsBox {
  height: calc(100vh - 215px);
  border: solid 1px transparent;
  margin: 5px 0px 5px 0px;
  text-decoration: none;

  p {
    margin: 0;
    overflow: hidden;
    color: #000000;
    word-wrap: break-word;

    &.title {
      font-weight: bold;
    }
  }

  /deep/ .dx-scrollable-scroll-content {
    background-color: rgba(44, 44, 44, 0.7);
    border-radius: 4px;
    width: 5px;
  }

  /deep/ .dx-scrollable-scroll {
    width: 8px !important;
  }
}

/deep/ .baseBox {
  background-color: #3653bf;
}

/deep/ .baseSquareBox {
  background-color: #3653bf;
}

/deep/ .baseBox {
  background: linear-gradient(to right, #3653bf 0 10px, #f3f4f6 0);
}

.projectParticipantsContents {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
}

.errorText {
  color: @error600;
  margin: 5px;
}

.popupInput {
  height: 100%;
  padding: 15px;

  h4 {
    color: @gray900;
  }

  .popupSelectBox {
    height: calc(100% - 59px);
    max-width: 700px !important;
  }
}
/deep/ .popupHeader {
  padding: 15px;
}
</style>
