<!-- eslint-disable vue/valid-v-model -->

<template>
  <div class="boxesBody">
    <div class="titleBox">
      <h5>{{ $t('columnTitles.SummaryAndConclusion') }}</h5>
      <div class="boxesSmallButtons">
        <BaseSquareBox icon="fa-solid fa-plus" :on-click="toggleAddSummaryAndConclusionPopup" />
      </div>
    </div>
    <div class="summaryBox">
      <DxScrollView :key="summaryAndConclusions.length" ref="dxScrollView" :useNative="true">
        <BaseBox v-for="summaryAndConclusion in summaryAndConclusions" :key="summaryAndConclusion.SmGUID" :boxItemInfo="summaryAndConclusion"
          :routeLink="`/project/${summaryAndConclusion.PrID}/summaryAndConclusions/${summaryAndConclusion.SmGUID}`"
          :sourceElementId="`summaryAndConclusion-${summaryAndConclusion.SmGUID}`" :boxType="boxTypeSelectable" :contextMenuItems="contextMenuItems"
          :isSelected="summaryAndConclusion.SmGUID === selectedSummaryAndConclusionsGuid" :isBorderVisible="isOnDetails"
          @delete="toggleDeleteSummaryAndConclusionPopup(summaryAndConclusion)" @click.self="itemClick">
          <div class="summaryBoxContents">
            <p class="title">{{ getTypeDescriptionForTypeCode(summaryAndConclusion.SmType) }}</p>
          </div>
        </BaseBox>
      </DxScrollView>
    </div>
    <transition name="fade" appear>
      <BasePopup v-show="deletePopupOpen" :popupOpen="deletePopupOpen" :popup-title="$t('project.DeleteSummaryAndConlcusion')"
        right-footer-button-text="project.DeleteButton" :createError="createError" @rightButtonClicked="deleteSummaryAndConclusion"
        @closeButtonClicked="toggleDeleteSummaryAndConclusionPopup">
        <p class="delete-text">{{ $t('project.DeleteSummaryAndConclusionHelp') }}</p>
      </BasePopup>
    </transition>
    <transition name="fade" appear>
      <BasePopup v-show="createPopupOpen" :popupOpen="createPopupOpen" :popup-title="$t('project.AddSummaryAndConclusionTitle')"
        right-footer-button-text="project.CreateButton" :createError="createError" @rightButtonClicked="createSummaryAndConclusion"
        @closeButtonClicked="toggleAddSummaryAndConclusionPopup">
        <SummaryAndConclusionsAddPopupContent v-model:value="inputTypeValue" :error="error" :typeOptions="typeOptions" />
      </BasePopup>
    </transition>
  </div>
</template>

<script>
import { DxScrollView } from 'devextreme-vue/scroll-view'
import sortUtils from '@/utils/sortUtils'
import BaseBox from '@/components/boxes/base-components/baseBox'
import BaseSquareBox from '@/components/boxes/base-components/baseSquareBox'
import BasePopup from '@/components/boxes/base-components/basePopup.vue'
import SummaryAndConclusionsAddPopupContent from '@/components/boxes/boxes-content/summaryAndConclusionsAddPopupContent.vue'
import store from '@/vuex/store'
import { mapGetters } from 'vuex'
import moment from 'moment'

import '@/assets/css/boxes.less'

export default {
  name: 'SummaryAndConclusionBoxes',
  components: {
    BaseBox,
    BaseSquareBox,
    DxScrollView,
    BasePopup,
    SummaryAndConclusionsAddPopupContent
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    selectedSummaryAndConclusionsGuid: {
      type: String,
      required: false,
      default: null
    }
  },
  async mounted() {
    await this.getTypeOptions()
    if (!this.selectedSummaryAndConclusionsGuid) return
    this.scrollToElement()
  },
  data() {
    return {
      guidName: 'SmGUID',
      currentSorting: 'SmType',
      currentSortOrder: 1,
      createPopupOpen: false,
      inputTypeValue: '',
      deletePopupOpen: false,
      itemToDelete: {},
      error: false,
      createError: true,
      typeOptions: [],
      contextMenuItems: [
        {
          text: this.$t('project.DeleteButton'),
          icon: 'trash'
        }
      ]
    }
  },
  computed: {
    summaryAndConclusions() {
      if (!this.$store.state.summaryAndConclusions) return
      let sortedData = this.$store.state.summaryAndConclusions.filter((data) => {
        return data
      })
      sortedData.sort((mpA, mpB) => sortUtils.naturalSort(mpA[this.currentSorting], mpB[this.currentSorting]) * this.currentSortOrder)
      return sortedData
    },
    boxTypeSelectable() {
      return this.selectedSummaryAndConclusionsGuid ? 'SmGUID' : ''
    },
    isOnDetails() {
      return !!this.selectedSummaryAndConclusionsGuid
    },

    ...mapGetters(['getSummaryAndConclusionsTypes', 'getSummaryAndConclusions'])
  },
  watch: {
    valueUser: {
      handler: function () {
        this.error = false
        this.createError = false
      }
    },
    selectedSummaryAndConclusionsGuid: {
      handler: function () {
        this.scrollToElement()
      }
    }
  },
  methods: {
    getTypeDescriptionForTypeCode(SmType) {
      return this.getSummaryAndConclusionsTypes.find((obj) => obj.key === SmType).value
    },

    toggleAddSummaryAndConclusionPopup() {
      this.error = false
      this.createError = false
      this.createPopupOpen = !this.createPopupOpen
      if (!this.createPopupOpen) this.inputTypeValue = ''
    },

    toggleDeleteSummaryAndConclusionPopup(item) {
      this.error = false
      this.createError = false
      if (item) {
        this.itemToDelete = item
      }
      this.deletePopupOpen = !this.deletePopupOpen
    },

    scrollToElement() {
      const selectedBaseBox = this.$refs.dxScrollView.$children.find((box) => box.$vnode.key === this.$route.params.SmGUID)
      let calculateMiddle = selectedBaseBox.$el.getBoundingClientRect().top - this.$refs.dxScrollView.$el.getBoundingClientRect().height / 2
      this.$refs.dxScrollView.instance.scrollBy({ left: 0, top: calculateMiddle })
    },

    createListOfPossibleNewSummaryAndConclusionTypes(typeList) {
      this.getSummaryAndConclusions.forEach((element) => {
        let index = typeList.findIndex((item) => item.key === element.SmType)
        if (index >= 0) {
          typeList.splice(index, 1)
        }
      })
      return typeList
    },

    async getTypeOptions() {
      if (this.$store.state.summaryAndConclusionsTypes.length < 1) {
        await this.$store.dispatch('setSummaryAndConclusionsTypes', 84)
      }
      let summaryAndConclusionTypesList = [...this.getSummaryAndConclusionsTypes]
      let sortedTypeList = []
      summaryAndConclusionTypesList = this.createListOfPossibleNewSummaryAndConclusionTypes(summaryAndConclusionTypesList)
      summaryAndConclusionTypesList.forEach((type) => {
        sortedTypeList.push(type.value)
      })
      this.typeOptions = sortedTypeList.sort((a, b) => sortUtils.naturalSort(a, b))
    },

    async nextRouter(newSummaryAndConclusionGUID, projectId) {
      await this.getTypeOptions()
      this.error = false
      this.createError = false
      this.createPopupOpen = false
      this.inputTypeValue = ''
      this.$router.push({
        name: 'summaryAndConclusion',
        params: {
          projectId: projectId,
          SmGUID: newSummaryAndConclusionGUID
        },
        query: { new: 'true' }
      })
    },

    async goToFirstSummaryAndConclusionIfExists() {
      const summaryAndConclusions = this.$store.state.summaryAndConclusions

      if (!summaryAndConclusions || summaryAndConclusions.length === 0) {
        this.$store.commit('clearWorkingObject')
        this.$router.push({ name: 'project', params: { id: this.projectId } })
        return
      }

      const targetRoute = { name: this.$router.history.current.name, params: { id: this.projectId, SmGUID: summaryAndConclusions[0].SmGUID } }
      await this.getTypeOptions()
      this.$router.push(targetRoute).catch((error) => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.log(error)
        }
      })
    },

    async createSummaryAndConclusion() {
      let matchingOption = store.state.summaryAndConclusionsTypes.find((option) => {
        return option.value === this.inputTypeValue
      })
      if (!matchingOption) {
        this.error = true
        return
      }
      let data = {
        PrID: store.state.workingObject.PrID,
        SmType: matchingOption.key,
        SmDateLastChanged: moment().format('YYYY-MM-DDTHH:mm:ss')
      }
      try {
        this.$store.dispatch('createSummaryAndConclusion', data).then((createResult) => {
          let newSummary = createResult.find((summary) => {
            return summary.SmType === data.SmType
          })
          this.$store.dispatch('fetchTblSummaryConclusions', { PrID: this.projectId }).then(() => {
            this.nextRouter(newSummary.SmGUID, data.PrID)
          })
        })
      } catch (error) {
        this.createError = true
        console.error(error)
      }
    },

    deleteSummaryAndConclusion() {
      this.$store
        .dispatch('deleteSummaryAndConclusion', {
          projectId: this.projectId,
          SmGUID: this.itemToDelete.SmGUID
        })
        .then(() => {
          this.$store.dispatch('fetchTblSummaryConclusions', { PrID: this.projectId }).then(async () => {
            this.error = false
            this.createError = false
            this.deletePopupOpen = false
            await this.getTypeOptions()
            if (this.onDetailsPage) {
              this.goToFirstSummaryAndConclusionIfExists()
            }
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.boxesBody {
  width: 200px;
  float: left;
  scroll-behavior: smooth;
  padding: 0px 10px 0;
  text-decoration: none;
}

.summaryBox {
  height: calc(100vh - 215px);
  border: solid 1px transparent;
  margin: 5px 0px 5px 0px;
  text-decoration: none;

  /deep/ .dx-scrollable-scroll-content {
    background-color: rgba(44, 44, 44, 0.7);
    border-radius: 4px;
    width: 5px;
  }

  /deep/ .dx-scrollable-scroll {
    width: 8px !important;
  }
}

/deep/ .baseBoxMark {
  background-color: #fdb022;
}

/deep/ .baseSquareBox {
  background-color: #fdb022;

  .baseSquareBoxIcon {
    color: #ffffff;
  }
}

/deep/ .baseBoxFlagTriangle {
  border-color: #fdb022;
}

/deep/ .selected .baseBox {
  border: 1px solid #fdb022;
}

/deep/ .baseBox {
  background: linear-gradient(to right, #fdb022 0 10px, #f3f4f6 0);
}

.delete-text {
  padding: 15px;
}
/deep/ .popupHeader {
  padding: 15px;
}
</style>
