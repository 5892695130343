<template>
  <button class="baseSquareBox" @click="onClick">
    <font-awesome-icon :icon="icon" class="baseSquareBoxIcon" />
  </button>
</template>

<script>
export default {
  name: 'BaseSquareBox',
  props: {
    icon: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.baseSquareBox {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  transition: all 0.2s ease-in-out;
}
.baseSquareBox:hover {
  transform: scale(0.92);
}
.baseSquareBoxIcon {
  height: 20px;
  color: white;
}
</style>
